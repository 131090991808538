import React from 'react';

import { LOADING_OPTIONS, Text } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { APPS_LINKS } from '@shared/constants/apps';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps images
import DownloadInstall from '@images/product-page/download-install.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
import UpgradeAnytime from '@images/product-page/upgrade-anytime.svg';
// Perks images
import UnlimitedAccess from '@images/product-page/unlimited-access.svg';
import HassleFree from '@images/product-page/hassle-free.svg';
import UnlockWorldwide from '@images/product-page/unlock-worldwide.svg';
import ZeroLogs from '@images/product-page/zero-logs.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForFireTv:metaData.title'),
    description: t('vpnForFireTv:metaData.description'),
    image: META_IMAGE,
    keywords: [
      'vpn for fire tv',
      'fire tv vpn',
      'free vpn for fire tv',
      'free vpn for firestick',
      'roku fire stick',
      'best vpn for firestick',
      'firestick vpn',
      'vpn for amazon fire stick',
    ],
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForFireTv:headerCta.title'),
      subtitle: t('vpnForFireTv:headerCta.subtitle'),
      description: [
        { title: t('vpnForFireTv:headerCta.productFeatures.optimizedServers') },
        { title: t('vpnForFireTv:headerCta.productFeatures.changeLocation') },
        { title: t('vpnForFireTv:headerCta.productFeatures.unlimitedBandwidth') },
        { title: t('vpnForFireTv:headerCta.productFeatures.quickSetup') },
      ],
      buttons: [
        {
          title: t('common:actions.download'),
          link: APPS_LINKS.fireTv,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.fireTv },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/fire-tv-header-image-mobile.png'),
        alt: 'fire tv page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/fire-tv-header-image-tablet.png'),
        alt: 'fire tv page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/fire-tv-header-image.png'),
        alt: 'fire tv page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2375px',
        position: {
          top: -80,
          height: 723,
          maxWidth: 2375,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForFireTv:stepCards.title'),
    description: t('vpnForFireTv:stepCards.description'),
    items: [
      {
        image: DownloadInstall,
        step: 1,
        title: t('vpnForFireTv:stepCards.downloadInstall.title'),
        description: t('vpnForFireTv:stepCards.downloadInstall.description'),
      },
      {
        image: UpgradeAnytime,
        step: 2,
        title: t('vpnForFireTv:stepCards.upgradeAnytime.title'),
        description: t('vpnForFireTv:stepCards.upgradeAnytime.description'),
      },
      {
        image: ConnectEnjoy,
        step: 3,
        title: t('vpnForFireTv:stepCards.connectEnjoy.title'),
        description: t('vpnForFireTv:stepCards.connectEnjoy.description'),
      },
    ],
  }),
  digitalLife: (t) => ({
    title: t('vpnForFireTv:elevateStreamExperience'),
    description: t('vpnForFireTv:manyFeatures'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/high-speed.png'),
        title: t('vpnForFireTv:contentBlocks.speed.title'),
        description: t('vpnForFireTv:contentBlocks.speed.description'),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: UnlockWorldwide,
        title: t('vpnForFireTv:perkCards.unlockWorldwide.title'),
        description: t('vpnForFireTv:perkCards.unlockWorldwide.description'),
      },
      {
        image: UnlimitedAccess,
        title: t('vpnForFireTv:perkCards.unlimitedAccess.title'),
        description: t('vpnForFireTv:perkCards.unlimitedAccess.description'),
      },
    ],
  }),
  onlineSafety: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/safety-for-activities.png'),
        title: t('vpnForFireTv:contentBlocks.protection.title'),
        description: (
          <React.Fragment>
            <span>{t('vpnForFireTv:contentBlocks.protection.description')}</span>
            <Text.Body3 as="span" display="block" mt={16}>
              {t('common:wireGuardTrademark')}
            </Text.Body3>
          </React.Fragment>
        ),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
    items: [
      {
        image: ZeroLogs,
        title: t('vpnForFireTv:perkCards.zeroLogs.title'),
        description: t('vpnForFireTv:perkCards.zeroLogs.description'),
      },
      {
        image: HassleFree,
        title: t('vpnForFireTv:perkCards.hassleFree.title'),
        description: t('vpnForFireTv:perkCards.hassleFree.description'),
      },
    ],
  }),
  reviews: (t) => ({
    title: t('vpnForFireTv:reviews.title'),
  }),
  moneyBackGuarantee: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForFireTv:pricingSection.title'),
    description: t('vpnForFireTv:pricingSection.description'),
    footerProps: {
      text: t('vpnForFireTv:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.fireTv,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.fireTv },
        },
      },
    },
  }),
  faq: ({ locationCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Is Atlas VPN a free VPN for Fire TV?',
        content:
          'At this time, Atlas VPN does not offer free VPN for FireStick and Fire TV. Our app for Amazon Fire devices, along with the app for Android TV, are available only with our Premium subscription. However, customers can try Atlas VPN for free on Windows, macOS, Android, and iOS devices.',
      },
      {
        header: 'What are the perks of a Premium subscription?',
        listLabel:
          'Atlas VPN Premium members can take advantage of many perks. With our Premium subscription, you get:',
        listItems: [
          `Over ${SERVER_COUNT} servers in ${locationCount} locations across the world`,
          'Video and gaming-optimized servers',
          'Apps for Android TV, Fire TV',
          '24/7 live support',
          'No data caps',
          'Privacy Pro servers',
          'An unlimited number of devices: secure all your devices, not just your smart TV. Try our apps for Windows, Android, macOS, iOS, and Linux.',
        ],
      },
      {
        header: 'Do I need a FireStick VPN?',
        content: `There are many benefits to using FireStick and Fire TV VPN. Without a VPN, your online activity can be tracked by your Internet Service Provider, the government, hackers, and snoopers. VPN encrypts your internet connection and hides your actual IP address shielding your online activity and data from everyone but yourself. By providing you with a virtual IP address, VPN also helps you overcome internet restrictions and censorship and access content around the world.`,
      },
      {
        header: 'How many devices can I connect to Atlas VPN?',
        content:
          'We offer unlimited simultaneous connections, so you can protect all your valuable devices with a single account as long as they are compatible with Atlas VPN. Currently, we have apps for iOS, macOS, Windows, Android, Android TV, as well as VPN for Amazon Fire Stick and Fire TV.',
      },
      {
        header: 'Is Atlas VPN compatible with all smart TVs?',
        content:
          'Atlas VPN offers apps for both Android TV and the majority of Amazon Fire devices, with the exception of 1st and 2nd generation Fire TV and Fire TV Stick. Currently, Atlas VPN does not support the Roku Fire Stick.',
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForFireTv:footerCta.title'),
      description: t('vpnForFireTv:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'Fire tv footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'Fire tv footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'Fire tv footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'Fire tv footer',
      },
      desktop: {
        src: footerImage,
        alt: 'Fire tv footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
